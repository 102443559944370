<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-size="headerSize"
      :header-position="headerPosition"
      :header-link="link"
      :bodytext="bodytext"
      :logo="logo"
      :image="image"
      :image-position="imagePosition"
      :banner-text-color="bannerTextColor"
      :is-gradient="gradientMask"
      :gradient-color="colorGradient"
      :gradient-direction="gradientAlignment"
      :gradient-opacity="gradientTransparency"
      :theme-color="theme"
      :tag-icon="tagIcon"
      :tag-list="tagList"
      :button="button"
      :label="label"
      :lang="currentLocale"
      class="t3-ce-case-study-banner"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useButton, useCurrentLocale, useLabel } from '#imports'

import type { T3CeCaseStudyBannerProps } from '~shared/types'

const props = withDefaults(defineProps<T3CeCaseStudyBannerProps>(), {
  uid: 0,
  index: 0,
  appearance: () => ({
    frameClass: '',
    layout: '',
    spaceAfter: '',
    spaceBefore: ''
  }),
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: '',
  bodytext: '',
  theme: 'default'
})

const currentLocale = useCurrentLocale()
const button = useButton(props)
const label = useLabel(props)
const tagList = computed(() => {
  if (!Array.isArray(props.items)) return []
  return props.items.map(({ content, id }) => ({ id, ...content }))
})

const HeroBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/HeroBanner')
)
const LandingHeroBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/LandingHeroBanner')
)
const TextImageBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/TextImageBanner')
)
const CareerBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/CareerBanner')
)
const ContactCtaBox = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/ContactCtaBox')
)
const SubpageBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/SubpageBanner')
)
const ContactCtaBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/ContactCtaBanner')
)
const TextImageWithAccordion = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/TextImageWithAccordion')
)
const ImagesWithTestimonial = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/ImagesWithTestimonial')
)
const ProductHeroBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCaseStudyBanner/ProductHeroBanner')
)

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    case 'textImageBanner':
      return TextImageBanner
    case 'careerBanner':
      return CareerBanner
    case 'contactCtaBox':
      return ContactCtaBox
    case 'layout-1': // TODO Remove after updating product pages, then remove from typo3 panel
      return SubpageBanner
    case 'landingHeroBanner':
      return LandingHeroBanner
    case 'contactCtaBanner':
      return ContactCtaBanner
    case 'textImageWithAccordion':
      return TextImageWithAccordion
    case 'imagesWithTestimonial':
      return ImagesWithTestimonial
    case 'productHeroBanner':
      return ProductHeroBanner
    default:
      return HeroBanner
  }
})
</script>
